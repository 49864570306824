<template>
    <div class="home" v-loading="loading">
        <!-- 头部 -->
        <!-- <largeHead :tabArrShow="tabArrShow"></largeHead> -->

        <!-- 主体内容 -->
        <div class="swiper">
            <swiper :options="swiperOption" ref="mySwiper" style="height:100%;width:100%;" v-if="showSwiper">
                <swiper-slide v-for="i in VrExhibition" :key="i.zhId"><img @click.prevent="exhibitionInfo(i.zhId)"
                        style="object-fit: cover;object-position: center center;width: 100%;height: 100%;" :src="i.picture"
                        alt="" /></swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
        </div>

        <!-- 全部展会 -->
        <div style="width: 100%; display: flex;align-items: center;justify-content: center;">
            <img style="width: 160px;height: auto" src="@/assets/qbzh.png" alt="">
        </div>
        <div class="contentEhibition" v-if="showSwiper">
            <div class="mainEhibition" @click="exhibitionInfo(VrExhibition[0].zhId)">
                <img :src="VrExhibition[0].picture" alt="">
                <div class="shadow">{{ VrExhibition[0].title }}</div>
            </div>
            <div class="ehibition" v-for="(i, index) in VrExhibition" :key="index" @click="exhibitionInfo(i.zhId)">
                <div class="img">
                    <img :src="i.picture" alt="">
                </div>
                <div class="name">
                    {{ i.title }}
                </div>
                <div class="time">{{ i.startTime.substring(0, 10) }} - {{ i.endTime.substring(0, 10) }}</div>
                <div class="addressName">{{ i.sponsor }}</div>
                <div class="address">{{ i.address }}</div>
            </div>
        </div>

        <!-- 分页 -->
        <paging :total="paging.total" @handleCurrentChange="handleCurrentChange" :page-size="paging.size"></paging>

        <!-- 底部 -->
        <!-- <bottomContent></bottomContent> -->
    </div>
</template>

<script>
import largeHead from "@/components/largeHead.vue";
import bottomContent from "@/components/bottomContent.vue";
import { getVrExhibitionList } from "@/utils/api/homeApi/index"
import paging from "@/components/paging.vue";
export default {
    name: "moreExhibitions",
    components: {
        largeHead,
        bottomContent,
        paging
    },
    computed: {
        VrExhibitionWithLoop() {
            if (this.VrExhibition && this.VrExhibition.length > 0) {
                let arr = [...this.VrExhibition];
                arr.unshift(this.VrExhibition[this.VrExhibition.length - 1]);
                arr.push(this.VrExhibition[0]);
                return arr;
            }
            return [];
        }
    },
    data() {
        return {
            // 头部激活菜单
            tabArrShow: 7,
            swiperOption: {
                slidesPerView: 1,
                // 设置分页器
                pagination: {
                    el: ".swiper-pagination",
                    // 设置点击可切换
                    clickable: true,
                    dynamicBullets: true,
                },
                // 设置自动轮播
                autoplay: {
                    delay: 5000, // 5秒切换一次
                },
                // 设置轮播可循环
                loop: true,
            },
            paging: {
                size: 10,
                current: 1,
                total: 0
            },
            VrExhibition: [],
            showSwiper: false,
            loading: true,
        };
    },
    mounted() {
        this.getVrExhibition()
    },
    methods: {
        // 分页
        handleCurrentChange(val) {
            this.loading = true
            console.log(val)
            this.paging.current = val
            this.getVrExhibition()
        },
        exhibitionInfo(zhId) {
            // 返回顶部
           this.$store.commit('scrollToTop')
            this.$router.push(`/exhibitionInfo?zhId=${zhId}&actNum=7`)
        },
        // 获取vr展会
        async getVrExhibition() {
            const res = await getVrExhibitionList(this.paging)
            if (res.statusCode = 8201) {
                this.VrExhibition = res.data.records
                this.paging.total = res.data.total
                this.showSwiper = true
                this.loading = false
            }
        }
    }
}
</script>

<style lang="less" scoped>
.home {
    width: 100%;
    min-height: 100vh;
    background-color: #f5f5f5;

    // 顶部轮播图
    >.swiper {
        width: 1200px;
        height: 380px;
        margin: 10px auto;
    }

    // 展会列表
    >.contentEhibition {
        width: 1200px;
        height: auto;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        >.mainEhibition {
            width: 590px;
            height: 270px;
            overflow: hidden;
            position: relative;
            cursor: pointer;

            >img {
                width: 100%;
                height: 100%;
                border-radius: 1px;
            }

            >.shadow {
                width: 100%;
                height: 40px;
                position: absolute;
                bottom: 0;
                color: #fff;
                line-height: 40px;
                padding-left: 20px;
                box-sizing: border-box;
                background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, .7));
                transition: all .3s;
            }
        }

        >.mainEhibition:hover {
            >.shadow {
                color: #ff4242;
                transition: all .3s;
            }
        }

        >.ehibition {
            width: 285px;
            height: 270px;
            padding: 20px;
            box-sizing: border-box;
            background-color: #fff;
            border: 1px solid #fff;
            transition: all .3s;
            margin-bottom: 46px;
            cursor: pointer;

            >.img {
                width: 100%;
                height: 110px;
                overflow: hidden;

                >img {
                    width: 100%;
                    height: auto;
                }
            }

            >.name {
                width: 100%;
                font-size: 16px;
                color: #333;
                white-space: nowrap;
                /* 禁止换行 */
                overflow: hidden;
                /* 超出部分隐藏 */
                text-overflow: ellipsis;
                /* 超出部分显示省略号 */
                margin-top: 16px;
                transition: all .3s;
            }

            >.time,
            .addressName,
            .address {
                font-size: 12px;
                color: #333;
                transition: all .3s;
            }

            >.time {
                margin-top: 4px;
                margin-bottom: 27px;
            }

            >.address {
                margin-top: 4px;
            }
        }

        >.ehibition:hover {
            border: 1px solid rgba(225, 36, 27, .5);
            transition: all .3s;

            >.time,
            .addressName,
            .address {
                font-size: 12px;
                color: #ff4242;
            }

            >.name {
                color: #ff4242;
            }
        }

    }

    // 分页
    .paging {
        width: 1200px;
        height: 50px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 50px;
    }
}

/deep/ .swiper-pagination-bullet-active {
    background-color: #ff4242 !important;
}

// 分页样式
/deep/ .el-pagination .active {
    background-color: #ff4242 !important;
    transition: all .3s !important;
}

/deep/ .el-pagination:hover .active:hover {
    color: #fff !important;
    transition: all .3s !important;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled) {
    background-color: #fff;
    border: 1px solid #fff;
    transition: all .3s !important;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #ff4242;
    border: 1px solid #ff4242;
    transition: all .3s !important;
}

/deep/ .el-icon-arrow-right:hover {
    color: #ff4242 !important;
    transition: all .3s !important;
}

/deep/ .el-icon-arrow-left:hover {
    color: #ff4242 !important;
    transition: all .3s !important;
}
</style>